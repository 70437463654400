import Vue from 'vue'

// axios
import axios from 'axios'

const axiosPlat = axios.create({
  // You can add your headers here
  // ================================
  baseURL: config.VUE_CONFIG_PLATFORM,
  // baseURL: 	'https://gamelogic.bingo-room.bingolar.tv',
 //   baseURL:'https://bingoroom-services.bingoroom.147.124.216.125.nip.io',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$httpPlatform = axiosPlat

export default axiosPlat
